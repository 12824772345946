import React from "react";
import {  useGetTopCryptoNewsQuery } from "../services/cryptoNewsApi";
import { Typography, Card} from "antd";
import Loader from "./Loader";
import parse from "html-react-parser"; // Import the library for parsing HTML


const { Title, Text } = Typography;

// Utility function to truncate the description to 22 words
const truncateDescription = (htmlDescription, wordLimit = 22) => {
  // Strip the HTML tags and extract plain text
  const textOnly = htmlDescription.replace(/<\/?[^>]+(>|$)/g, ""); // Remove HTML tags
  const words = textOnly.split(/\s+/); // Split into words
  return words.length > wordLimit
    ? words.slice(0, wordLimit).join(" ") + "..."
    : textOnly;
};

const News = ({ simplified }) => {
    const count = simplified ? 6 : 100;
    
  const { data, isFetching, error } = useGetTopCryptoNewsQuery( count);

  if (isFetching) return <Loader />;
  if (error) return <Text>Error: {error.message}</Text>;

  console.log("API Data:", data);

  // Validate the response structure and check if `data` exists
  let newsArray = data?.data || []; newsArray = newsArray.slice(0, count);
  if (newsArray.length === 0) {
    return <Text>No news available at the moment.</Text>;
  }

  return ( <>
    <div style={{ padding: "20px" }}>
      <Title level={2}>Top Crypto News</Title>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
        {newsArray.map((news, index) => (
          <Card
            key={index}
            title={news.title}
            style={{ width: "300px" }}
            cover={<img alt={news.title} src={news.thumbnail} style={{
                width: "100%",       // Make the image take the full width of the card
                height: "200px",     // Set a fixed height for the image
                objectFit: "cover",  // Ensure the image scales properly
                borderTopLeftRadius: "8px", // Optional: add rounded corners to match card styling
                borderTopRightRadius: "8px",
               
                 
               }} />}
          >
            <p>{parse(truncateDescription(news.description))}</p>
            <a href={news.url} target="_blank" rel="noopener noreferrer" >
              Read More
            </a>
            <Text
              style={{
                display: "block",
                marginTop: "10px",
                fontSize: "12px",
                
              }}
            >
              Published: {new Date(news.createdAt).toLocaleString()}
            </Text>
          </Card>
        ))}
      </div>
    </div>
    
  </>
  );
};

export default News;
