import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define the headers for the API
const cryptoApiHeaders = {
  "x-rapidapi-key": process.env.REACT_APP_RAPIDAPI_KEY,
  "x-rapidapi-host": process.env.REACT_APP_NEWS_RAPIDAPI_HOST,
};

// Define the base URL for the API
const baseUrl = process.env.REACT_APP_NEWS_API_URL;

// Function to create a request with headers
const createRequest = (url) => ({ url, headers: cryptoApiHeaders });

// Define the API using Redux Toolkit Query
export const cryptoNewsApi = createApi({
  reducerPath: "cryptoNewsApi",
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    getTopCryptoNews: builder.query({
      query: (count) => createRequest(`/cryptodaily?count=${count}`),
    }),
    getAllCryptoNews: builder.query({
      query: () => createRequest(`/news/all`),
    }),
  }),
});

// Export hooks for API queries
export const { useGetTopCryptoNewsQuery, useGetAllCryptoNewsQuery } =
  cryptoNewsApi;
